import { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from 'react-bootstrap-icons'
import headerImg from '../assets/img/header-img.png'
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";


export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [shownText, setShownText] = useState('');
    const [delta, setDelta] = useState(50 - Math.random() * 100);
    const toRotate = [ "Full Stack", "Gamer", "Cook", "Green Thumb"];
    const period = 2000;

    const tick = () => {
      let i = loopNum % toRotate.length;
      let fullText = toRotate[i];
      let updatedText = isDeleting ? fullText.substring(0, shownText.length - 1) : fullText.substring(0, shownText.length + 1);
  
      setShownText(updatedText);
  
      if (isDeleting) {
        setDelta(prevDelta => prevDelta / 2);
      }
  
      if (!isDeleting && updatedText === fullText) {
        setIsDeleting(true);
        setDelta(period);
      } else if (isDeleting && updatedText === '') {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
        setDelta(200);
      }
    }
  
    useEffect(() => {
      let ticker = setInterval(() => {
        tick();
      }, delta);
  
      return () => { clearInterval(ticker) };
    })
  
    

    return (
      <Router>
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                    <span className="tagline">
                        Welcome to my Portfolio, have fun!
                    </span>
                    <h1>{`Hi, I'm João `}</h1>
                    <span className="wrap">|{shownText}</span>
                    <p>Hi! I'm João Lindeza, a Full Stack dev that enjoys peace of life and some other nerdy stuff. Feel free to check further information about me below :)</p>
                      <HashLink to='#connect'>
                        <button>Contact me!<ArrowRightCircle size={25} /></button>
                      </HashLink>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                    <img id="header-img" src={headerImg} alt="Header Img"/>
                    </Col>
                </Row>
            </Container>
        </section>
      </Router>
    )
}