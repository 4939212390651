export const SkillBar = (props) => {

    return (
        <div className="skill-container">
            <h3 className="skill-title">{props.title}</h3>
            <div className="skill-bar">
                <span className= {"skill-bar-cover skill-"+ props.percentage}>
                    <span className="skill-tooltip">{props.percentage}%</span>
                </span>
            </div>
        </div>
    )
}