import {Col} from "react-bootstrap"

export const ProjectCard = ({title, technologies, description, imgUrl}) => {
    return (
        <Col sm={6} md={4}>
            <div className="proj-imgbx">
                <div className="proj-overlay">{technologies}</div>
                <img src={imgUrl} alt="Project Banner"/>
                <div className="proj-txtx">
                    <h4>{title}</h4>
                    <span>{description}</span>
                </div>
            </div>
        </Col>
    )
}