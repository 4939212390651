import {useState} from 'react'
import { Col, Container, Row } from "react-bootstrap"
import linkedInLogo from "../assets/img/nav-icon1.svg"
import emailIcon from "../assets/img/email_icon.png"

export const Contact = (props) => {
    const formInitialDetails = {
        firstName: '',
        lastName: '',
        subject: '',
        message: ''
    }
    
    const [formDetails, setFormDetails] = useState(formInitialDetails)
    const [buttonText, setButtonText] = useState('Send')
    const [status, setStatus] = useState({})

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    const handleSubmit = (e) => {

        e.preventDefault()

        if (!formDetails.subject || !formDetails.firstName || !formDetails.lastName || !formDetails.message) {
            setStatus({ success: false, message: "Please, fulfill all the fields :D"})

            return true
        } else if (formDetails.subject === ' ' || formDetails.firstName === ' ' || formDetails.lastName === ' ' || formDetails.message === ' ') {
            setStatus({ success: false, message: "Please, fulfill all the fields :D"})

            return true
        }

        try {
            window.open("mailto:joao.lindeza@hotmail.com?subject=" + formDetails.subject +"&body=Hi! I'm " + formDetails.firstName + " " + formDetails.lastName + "!%0D%0A%0D%0A" + formDetails.message)
            setStatus({ success: true, message: "Message sent successfully!"})
            setButtonText("Sent!")
        } catch  (error){
            setStatus({ success: false, message: "E-mail not sent, something went wrong :C"})
        }
    }

    const handleLinkedInClick = () => {
        window.open("https://www.linkedin.com/in/joao-lindeza/", '_blank');
    }

    return (
        <section className="contact" id="connect">
            <Container>
                <h2>
                    Get in Touch!
                </h2>
                <Row className="align-items-center">
                    <Col md={6} className= {props.windowWidth <= 767 ? "separator-under" : "separator-after"}>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <Row>
                                <Col sm={12} className= { props.windowWidth <= 575 ? "px-4" : "px-1" }>
                                    <input type="text" value={formDetails.subject} placeholder="Subject" onChange={(e) => onFormUpdate('subject', e.target.value)} />
                                </Col>
                                <Col sm={6} className= { props.windowWidth <= 575 ? "px-4" : "px-1" }>
                                    <input type="text" value={formDetails.firstName} placeholder="First Name" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                                </Col>
                                <Col sm={6} className= { props.windowWidth <= 575 ? "px-4" : "px-1" }>
                                    <input type="text" value={formDetails.lastName} placeholder="Last Name" onChange={(e) => onFormUpdate('lastName', e.target.value)} />
                                </Col>
                                <Col className= { props.windowWidth <= 575 ? "px-4 contact-submit-area" : "px-1 contact-submit-area" }>
                                    <textarea row="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)} />

                                    {
                                    status.message &&
                                    <Container fluid={true}>
                                        <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
                                    </Container>
                                }
                                    <button><span>{buttonText}</span></button>
                                </Col>
                            </Row>
                            
                        </form>
                    </Col>
                    <Col md={6}>
                         <Row>
                            <div className= {props.windowWidth <= 767 ? "contact-personals-mobile" : "contact-personals-wide"}>
                                <div className="contact-line"><img className="contact-logo" src={emailIcon} alt="E-mail"/><h5>joao.lindeza@hotmail.com</h5></div>
                                <div className="contact-separator"></div>
                                <div className="contact-line" onClick={() => handleLinkedInClick()}><img className="contact-logo" src={linkedInLogo} alt="Linked In"/><h5>joao-lindeza</h5></div>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}