import { Col, Container, Row } from "react-bootstrap";
import { SkillBar } from "./SkillBar";
import { useEffect } from "react";

export const Skills = () => {

    useEffect(() => {
        
        const revealSkills = () => {
            var revealElement = document.querySelectorAll(".reveal")

            for (var i = 0; i < revealElement.length; i++) {
                var windowHeight = window.innerHeight;
                var revealTop = revealElement[i].getBoundingClientRect().top;
                var revealPoint = 150;

                if (revealTop < windowHeight - revealPoint) {
                    revealElement[i].classList.add("active")
                }
            }
        }

        revealSkills()

        window.addEventListener("scroll", revealSkills);

        return() => window.removeEventListener("scroll", revealSkills);
    });

    return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skills-box">
                        <h2>Skills</h2>
                        <p>These are my skills, I always look forward to improve every one of them, to achieve web transcendence.</p>
                        <Container className="reveal">
                            <Row>
                                <Col md={6}>
                                    <SkillBar 
                                        title="HTML 5"
                                        percentage="95"/>
                                </Col>
                                <Col md={6}>
                                    <SkillBar 
                                        title="Software Engineering"
                                        percentage="85"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <SkillBar 
                                        title="Web Development"
                                        percentage="85"/>
                                </Col>
                                <Col md={6}>
                                    <SkillBar 
                                        title="Laravel"
                                        percentage="85"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <SkillBar 
                                        title="PHP"
                                        percentage="85"/>
                                </Col>
                                <Col md={6}>
                                    <SkillBar 
                                        title="CSS 3"
                                        percentage="85"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <SkillBar 
                                        title="JavaScript"
                                        percentage="80"/>
                                    
                                </Col>
                                <Col md={6}>
                                    <SkillBar 
                                        title="React"
                                        percentage="75"/>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                <SkillBar 
                                        title="Machine Learning"
                                        percentage="75"/>
                                </Col>
                                <Col md={6}>
                                    <SkillBar 
                                        title="MySQL"
                                        percentage="75"/>
                                </Col>
                            </Row>
                            
                            
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
