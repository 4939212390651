import { Col, Container, Row } from "react-bootstrap"
import navIcon1 from "../assets/img/nav-icon1.svg"
import githubIcon from '../assets/img/github.svg'

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={6}>
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                    <div className="social-icon">
                    <a href="https://github.com/CapHope"><img src={githubIcon} alt="GitHub"/></a>
                    </div>
                    <div className="social-icon">
                    <a href="https://www.linkedin.com/in/joao-lindeza/"><img src={navIcon1} alt="LinkedIn"/></a>
                    </div>
                    <p>CopyRight 2024. All Right Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}